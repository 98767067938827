import { useEffect, useRef, useState } from "react";
import {
  Flex,
  Heading,
  Button,
  Stack,
  chakra,
  InputGroup,
  Box,
  useToast,
  Image,
  InputLeftElement,
  Input,
  FormLabel,
  InputRightElement,
  FormControl,
} from "@chakra-ui/react";
import { FaUserAlt } from "react-icons/fa";
import companyLogo from "../../assets/logos/logofinaltopdown.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserDataApi from "../../utils/apis/userDataApi";
import { setUserCustomerEmail,setIsLoadingState, setUserSupplyNumber,setUserCustomerSupplyAddress,setUserSupplyId, setUserCustomerCountry, setUserCustomerSupplyNickname, setUserCustomerTin, setUserCustomerZipCode } from "../../redux/reducer-slices/userData";
import Layout from "../header/layout.header";

const CFaUserAlt = chakra(FaUserAlt);

function CreateOrFindMetaUser() {
  const errRef = useRef<HTMLParagraphElement>(null);
  const [errMsg, setErrMsg] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [userExistencetoggle, setUserExistencetoggle] = useState("");
  const { isLoading,user_customer_email } = useSelector((state:any) => state.user);
  const userDataApi = UserDataApi();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const toast = useToast();

  useEffect(()=>{
    setUserExistencetoggle("");
  },[])

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setErrMsg("");
      if(searchEmail==""){
        showMessage("Email cannot be empty");
        return
      }
      dispatch(setIsLoadingState(true));
      const response = await userDataApi.get<any>(
        `user-service/userdetails/email/${searchEmail}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response?.data?.id) {
        dispatch(setIsLoadingState(false));
        showMessage("user not found");
        setUserExistencetoggle("createNew");
        dispatch(setUserCustomerEmail(searchEmail));
        dispatch(setUserSupplyNumber(''));
        return;
      }else{
        dispatch(setIsLoadingState(false));
        showMessage("user found","success");
        dispatch(setUserCustomerEmail(response.data.email));
        dispatch(setUserCustomerTin(response.data.tin));
        dispatch(setUserCustomerZipCode(response.data.zipCode));
        dispatch(setUserCustomerCountry(response.data.country));
        searchForSupplyNumberByUserId(response.data.id);
        setUserExistencetoggle("exists");
        return
      }
    } catch (err: any) {
      dispatch(setIsLoadingState(false));
      if (!err?.response) {
        setErrMsg("No Server Response");
        showMessage("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("user not found");
        showMessage("user not found");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        showMessage("Unauthorized");
      }
      errRef.current !== null ? errRef.current.focus() : "";
    }
  };

  async function searchForSupplyNumberByUserId(customerId:string){
    try {
      setErrMsg("");
      if(searchEmail==""){
        showMessage("Email cannot be empty");
        return
      }
      const response = await userDataApi.get<any>(
        `user-service/supply/account/${customerId}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response?.data[0]?.number) {
        dispatch(setUserSupplyNumber(response.data[0].number));
        dispatch(setUserCustomerSupplyAddress(response.data[0].address));
        dispatch(setUserSupplyId(response.data[0].id));
        dispatch(setUserCustomerSupplyNickname(response.data[0].nickname));
        return;
      }
    } catch (err: any) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        showMessage("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg(`could not found ${user_customer_email} supply details`);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        showMessage("Unauthorized");
      }
      errRef.current !== null ? errRef.current.focus() : "";
    }
  }

  function showMessage(errMsg: string, status: "error" | "info" | "success"="error") {
    toast({
      title: errMsg,
      position: "top-right",
      isClosable: true,
      status: status,
    });
  }


  return (
    <>
    <Layout></Layout>
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={companyLogo}
          alt="ensight logo"
          boxSize="40%"
          height={'100%'}
          objectFit="contain"
        />
        <Heading
          color="#5AC4E5"
          fontFamily="monospace"
          fontWeight="bold"
          mb="4"
        >Find a customer account by email</Heading>
        <p
          ref={errRef}
          style={{ color: errMsg ? "red" : "inherit" }}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <Box minW={{ base: "90%", md: "468px" }}>
        <form onSubmit={handleSubmit}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl variant="floating">
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="gray.300"
                children={<CFaUserAlt color="gray.300" />}
              ></InputLeftElement>
              <Input
                type={"email"}
                onChange={(e) => {setSearchEmail(e.target.value);
                  setUserExistencetoggle("");}}
                value={searchEmail}
              />
            </InputGroup>
            </FormControl>
            <Button
                _hover={{ background: "#14A0BF" }}
                background={'#5AC4E5'}
                isLoading={isLoading}
                loadingText='searching...'
                borderRadius={0}
                type="submit"
                variant="solid"
                width="full"
                colorScheme={'teal'}
              >
                Search
              </Button>
              {userExistencetoggle==='createNew' && <Button
                isLoading={isLoading}
                loadingText='Submitting'
                borderRadius={0}
                variant="solid"
                  _hover={{ background: "#14A0BF" }}
                background={'#5AC4E5'}
                colorScheme="teal"
                width="full"
                onClick={()=>navigate("/create-device",{state:{action:'createNew'}})}
              > 
                add a device and add user
              </Button>}
              {userExistencetoggle==='exists' && <Button
                isLoading={isLoading}
                loadingText='Submitting'
                borderRadius={0}
                variant="solid"
                  _hover={{ background: "#14A0BF" }}
                background={'#5AC4E5'}
                colorScheme="teal"
                width="full"
                onClick={()=>navigate("/create-device",{state:{action:'exists'}})}
              >
                add device to that user
              </Button>}
            </Stack>
            </form>
        </Box>
      </Stack>
    </Flex>
    </>
  );
}

export default CreateOrFindMetaUser;