import axios from "axios";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";

const UserDataApi = ()=>{ 
  const { auth }:any = useContext(AuthContext);
  const instance = axios.create({
    baseURL: "https://ensightgateway.azurewebsites.net/",
    headers: {
      Authorization: `Bearer ${auth.accessToken??sessionStorage.getItem("authToken")}`,
    },
  });
  return instance;
};
export default UserDataApi;
