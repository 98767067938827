import { useEffect, useRef, useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  FormControl,
  FormHelperText,
  InputRightElement,
  useToast,
  Image,
  FormLabel,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import companyLogo from "../../assets/logos/logofinaltopdown.png";
import authUserApi from "../../utils/apis/authUserApi";
import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { setEmail, setIsLoadingState } from "../../redux/reducer-slices/userData";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

function TechnicianLoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);
  const { setAuth }: any = useAuth();
  const errRef = useRef<HTMLParagraphElement>(null);
  const [email, setUser] = useState("");
  const [password, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [extUserId, setExtUserId] = useState("63daa22b96890d0001464ebe");
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state:any) => state.user);
  const toast = useToast();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if(email=="" || password=="" ){
        showMessage("Email and Password cannot be empty");
        return
      }
      dispatch(setIsLoadingState(true));
      const response = await authUserApi.post<any>(
        "auth-service/auth/login",
        JSON.stringify({ email, password }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response) {
        dispatch(setIsLoadingState(false));
        if(!response?.data?.confirmed){
          showMessage(`verify your email before Log In. Check your email ${email}`);
        }else if(response?.data?.confirmed && !response?.data?.authToken){
          showMessage('user not found. Check your credentials')
        }
      }
      const accessToken = response?.data?.authToken;
      const roles = response?.data?.role;
      const userName = response?.data.email;
      const userId = response?.data.id;
      sessionStorage.setItem("authToken", accessToken);
      sessionStorage.setItem("userId", userId);
      sessionStorage.setItem("userName", userName);
      sessionStorage.setItem("uid", extUserId);
      setAuth({ email, userId, roles, accessToken, userName, extUserId });
      setUser("");
      setPwd("");
      dispatch(setEmail(email));
      dispatch(setIsLoadingState(false));
      navigate("/find-create-user", { replace: true });
    } catch (err: any) {
      dispatch(setIsLoadingState(false));
      if (!err?.response) {
        setErrMsg("No Server Response");
        showMessage("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
        showMessage("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        showMessage("Unauthorized");
      } else {
        setErrMsg("Login Failed");
        showMessage("Login Failed");
      }
      errRef.current !== null ? errRef.current.focus() : "";
    }
  };

  function showMessage(errMsg: string, status: "error" | "info" | "success"="error") {
    toast({
      title: errMsg,
      position: "top-right",
      isClosable: true,
      status: status,
    });
  }

  async function handleForgetPasswordClick(){
    try{
      if(email==""){
        setErrMsg("Please enter a valid email to reset the password.");
        return;
      }
      const response = await authUserApi.post<any>(
        "auth-service/auth/forgetpassword",
        JSON.stringify({ email:email, ConfirmationEmailUrl: "https://technicial-admin-panel-ui.pages.dev/reset-user-password/"}),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if(response?.data){
        showMessage(`We have send you an email with Instructions. Please check your email: ${email}`,"info");
        //navigate("/reset-user-password",{state:{resetPasswordUserId:response.data,userEmail:email}});
      }
    }catch(err: any){
      if (!err?.response) {
        setErrMsg("No Server Response");
        showMessage("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Email is invalid.");
        showMessage(err.response?.data.errors);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        showMessage("Unauthorized");
      } else {
        setErrMsg("Email is invalid.");
        showMessage(err.response?.data.errors);
      }
      errRef.current !== null ? errRef.current.focus() : ""; 
    }
  }

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={companyLogo}
          alt="ensight logo"
          boxSize="30%"
          height={'100%'}
          objectFit="contain"
        />
        <Heading
          color="#5AC4E5"
          fontFamily="monospace"
          fontWeight="bold"
          mb="4"
        ></Heading>
        <p
          ref={errRef}
          style={{ color: errMsg ? "red" : "inherit" }}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={handleSubmit}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl variant="floating">
                
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaUserAlt color="gray.300" />}
                  ></InputLeftElement>
                  <Input
                    type="email"
                    onChange={(e) => setUser(e.target.value)}
                    value={email}
                  />
                  <FormLabel mb='8px'>Email Address</FormLabel>
                </InputGroup>
              </FormControl>
              <FormControl variant="floating">
              
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  ></InputLeftElement>
                  <Input
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPwd(e.target.value)}
                    value={password}
                  />
                  <FormLabel mb='8px'>Password</FormLabel>
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                  
                </InputGroup>
                <FormHelperText textAlign="right">
                  <Link onClick={() => handleForgetPasswordClick()}>forgot password?</Link>
                </FormHelperText>                
              </FormControl> 
              <Button
                _hover={{ background: "#14A0BF" }}
                background={'#5AC4E5'}
                isLoading={isLoading}
                loadingText='Submitting'
                borderRadius={0}
                type="submit"
                variant="solid"
                width="full"
                colorScheme={'teal'}
              >
                Login
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
}

export default TechnicianLoginPage;
