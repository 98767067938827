import { useRef, useState,useEffect } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  FormControl,
  useToast,
  Image,
  FormLabel,
  Select,
  InputRightElement,
} from "@chakra-ui/react";
import { FaUserAlt } from "react-icons/fa";
import companyLogo from "../../assets/logos/logofinaltopdown.png";
import UserDataApi from "../../utils/apis/userDataApi";
import { useSelector, useDispatch } from "react-redux";
import {
  setUserPowerPhase,
  setIsLoadingState,
} from "../../redux/reducer-slices/userData";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../header/layout.header";
const CFaUserAlt = chakra(FaUserAlt);

function CreateDevicePage() {
  const errRef = useRef<HTMLParagraphElement>(null);
  const [deviceName, setDeviceName] = useState("");
  const [deviceLocation, setDeviceLocation] = useState(0);
  const [supplyNumber, setSupplyNumber] = useState("");
  const [customerAddress, setcustomerAddress] = useState("");
  const [customerCountry, setCustomerCountry] = useState("");
  const [customerZipCode, setCustomerZipCode] = useState(0);
  const [customerTin, setCustomerTin] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [supplyNickname, setSupplyNickName] = useState("");
  const [supplyAddressOvverride, setSupplyAddressOverride] = useState('');
  const dispatch = useDispatch();
  const { user_details, isLoading,user_customer_email,user_supply_nr,user_customer_supply_address,user_supply_id,user_supply_nickName,user_customer_tin,user_customer_zipCode,user_customer_country } = useSelector((state: any) => state.user);
  let navigate = useNavigate();
  const userDataApi = UserDataApi();
  const toast = useToast();
  const handleUpdateAddressClick = () => updateCustomerSupplyInformation();
  const location = useLocation();
  const action = location.state.action;
  
  useEffect(()=>{
    setSupplyAddressOverride(user_customer_supply_address);
  },[])

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(setIsLoadingState(true));
    try {
      const response = await userDataApi.post<any>(
        `/user-service/technician/AddDevice`,
        {
          deviceName: deviceName,
          supplyNickname: user_supply_nickName ?? supplyNickname,
          deviceLocation: deviceLocation,
          supplyNumber: user_supply_nr || supplyNumber,
          email: user_customer_email ?? customerEmail,
          address: supplyAddressOvverride?? customerAddress,
          country: user_customer_country?? customerCountry,
          zipCode: user_customer_zipCode?? customerZipCode,
          tin: user_customer_tin ?? customerTin,
          password:"",
          deviceTypeId: "00000000-0000-0000-0000-000000000000",
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.data.errorMessage) {
        navigate("/onboarding-success", { replace: true });
      }else{
        showMessage("Server Responsed with an error. Please verify your input and try again");
      }
      dispatch(setIsLoadingState(false));
    } catch (err: any) {
      dispatch(setIsLoadingState(false));
      if (!err?.response) {
        setErrMsg("No Server Response");
        showMessage("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("add device Failed");
        showMessage("add device Failed");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        showMessage("Unauthorized");
      } else {
        setErrMsg("add device Failed");
        showMessage("add device Failed");
      }
      errRef.current !== null ? errRef.current.focus() : "";
    }
  };

  async function updateCustomerSupplyInformation(){
    dispatch(setIsLoadingState(true));
    try {
      const response = await userDataApi.put<any>(
        `/user-service/supply/address`,
        {
          id: user_supply_id,
          address: supplyAddressOvverride,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.data.errorMessage) {
        showMessage("address updates successfuly","success");
      }
      dispatch(setIsLoadingState(false));
    } catch (err: any) {
      dispatch(setIsLoadingState(false));
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("update supply address failed");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        showMessage("Unauthorized");
      } else {
        setErrMsg("update supply address failed");
        showMessage("update supply address failed");
      }
      errRef.current !== null ? errRef.current.focus() : "";
    }
  }

  function showMessage(errMsg: string, status: "error" | "info" | "success"="error") {
    toast({
      title: errMsg,
      position: "top-right",
      isClosable: true,
      status: status,
    });
  }

  return (
    <>
    <Layout></Layout>
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={companyLogo}
          alt="ensight logo"
          boxSize="19%"
          height={"100%"}
          objectFit="contain"
        />
        <Heading color="#5AC4E5" fontFamily="monospace" fontWeight="bold" mb="4">
          Create Device
        </Heading>
        <p
          ref={errRef}
          style={{ color: errMsg ? "red" : "inherit" }}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={handleSubmit}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              {" "}
              <FormControl>
                <FormLabel mb="8px">Device Name(on device)</FormLabel>
                <InputGroup>
                  <Input
                    type="text"
                    required
                    placeholder=" "
                    onChange={(e) => setDeviceName(e.target.value)}
                    value={deviceName}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel mb="8px">Device location</FormLabel>
                <InputGroup>
                  <Select
                    value={deviceLocation}
                    placeholder=""
                    required
                    onChange={(e) => setDeviceLocation(e.target.value)}
                  >
                    <option value="0">Home</option>
                    <option value="1">Business</option>
                  </Select>
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel mb="8px">Customer Supply Number</FormLabel>
                <InputGroup>
                  <Input
                    disabled={user_supply_nr}
                    type="text"
                    placeholder=" "
                    required
                    onChange={(e) =>
                      setSupplyNumber(e.target.value.trim().replaceAll(" ", ""))
                    }
                    value={user_supply_nr? user_supply_nr : supplyNumber}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel mb="8px">Install device to (Supply Address):</FormLabel>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder=" "
                    onChange={(e) => setSupplyAddressOverride(e.target.value)}
                    value={supplyAddressOvverride}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleUpdateAddressClick}>
                      Update
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel mb="8px">Customer Email</FormLabel>
                <InputGroup>
                  <Input
                  //disable it only for an existing user, found in the create-or-find page 
                    disabled={user_customer_email && user_supply_nr}
                    type="text"
                    placeholder=" "
                    required
                    onChange={(e) => setCustomerEmail(e.target.value)}
                    value={user_customer_email? user_customer_email : customerEmail}
                  />
                </InputGroup>
              </FormControl>
              { action === "createNew" ? (
                <>
              <FormControl>
                <FormLabel mb="8px">Customer Address</FormLabel>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder=" "
                    required
                    onChange={(e) => setcustomerAddress(e.target.value)}
                    value={customerAddress}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel mb="8px">Customer Country</FormLabel>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder=" "
                    required
                    onChange={(e) => setCustomerCountry(e.target.value)}
                    value={customerCountry}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel mb="8px">Customer ZipCode</FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    placeholder=" "
                    required
                    onChange={(e) => setCustomerZipCode(e.target.value)}
                    value={customerZipCode}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel mb="8px">Customer tin number</FormLabel>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder=" "
                    required
                    onChange={(e) => setCustomerTin(e.target.value)}
                    value={customerTin}
                  />
                </InputGroup>
              </FormControl>
              </>
              ):""}
              <Button
                isLoading={isLoading}
                loadingText="Submitting"
                borderRadius={0}
                type="submit"
                variant="solid"
                  _hover={{ background: "#14A0BF" }}
                background={'#5AC4E5'}
                colorScheme="teal"
                width="full"
              >
                Add the device
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
    </>
  );
}
export default CreateDevicePage;
