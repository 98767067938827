import { createSlice } from '@reduxjs/toolkit'

export const userDataSlice = createSlice({
  name: 'userData',
  initialState: {
    userEmail: '',
    user_details:{
      user_id: '',
      user_name: ''
    },
    user_supply_nr: '',
    user_supply_id: '',

    user_supply_nickName: '',

    user_customer_supply_address: '',
    user_power_phase: '1',
    user_customer_ext_id: '',

    user_customer_tin: '',
    user_customer_zipCode: '',
    user_customer_country:'',

    user_customer_name: '',
    user_customer_email: '',
    isLoading: false,
  },
  reducers: {
    setEmail: (state,action) => {
      state.userEmail = action.payload;
    },
    setUserSupplyNumber: (state,action) => {
      state.user_supply_nr = action.payload;
    },
    setUserSupplyId: (state,action) => {
      state.user_supply_id = action.payload;
    },
    setUserPowerPhase: (state,action) => {
      state.user_power_phase = action.payload;
    },
    setUserCustomerExternalId: (state,action) => {
      state.user_customer_ext_id = action.payload;
    },
    setIsLoadingState: (state,action) => {
      state.isLoading = action.payload;
    },
    setUserCustomerName: (state,action) => {
      state.user_customer_name = action.payload;
    },
    //action for the customer email the came after searching for that customer
    setUserCustomerEmail: (state,action) => {
      state.user_customer_email = action.payload;
    },
    setUserCustomerSupplyAddress: (state,action) => {
      state.user_customer_supply_address = action.payload;
    },
    setUserCustomerSupplyNickname: (state,action) => {
      state.user_supply_nickName = action.payload;
    },
    setUserCustomerTin: (state,action) => {
      state.user_customer_tin = action.payload;
    },
    setUserCustomerZipCode: (state,action) => {
      state.user_customer_zipCode = action.payload;
    },
    setUserCustomerCountry: (state,action) => {
      state.user_customer_country = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const { 
  setEmail,
  setUserSupplyNumber,
  setUserSupplyId,
  setUserCustomerExternalId,
  setUserCustomerSupplyAddress,
  setIsLoadingState,
  setUserPowerPhase,
  setUserCustomerName,
  setUserCustomerSupplyNickname,
  setUserCustomerTin,
  setUserCustomerZipCode,
  setUserCustomerCountry,
  setUserCustomerEmail } = userDataSlice.actions

export default userDataSlice.reducer