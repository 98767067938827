import { useEffect, useRef, useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  FormControl,
  useToast,
  Image,
  InputRightElement,
  FormLabel,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import companyLogo from '../../assets/logos/logofinaltopdown.png';
import authUserApi from "../../utils/apis/authUserApi";
import useAuth from "../../hooks/useAuth";
import { setEmail } from "../../redux/reducer-slices/userData";
import Layout from "../header/layout.header";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);
  const { setAuth }: any = useAuth();
  const errRef = useRef<HTMLParagraphElement>(null);

  const [password, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  let navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const queryParams = new URLSearchParams(location.search);
  const resetPasswordUserId =  queryParams.get('key');
  const userEmail =  queryParams.get('email');
  const [resetToken, setResetToken] = useState(queryParams.get('key')??"");
  const [email, setEmail] = useState(queryParams.get('email'));
  useEffect(()=>{
    // setResetToken(resetPasswordUserId);
    // setEmail(userEmail);
  },[])
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const response = await authUserApi.post<any>(
        "auth-service/auth/ChangePassword",
        JSON.stringify({ key: resetToken,email: email, password: password }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if(response){
        navigate("/login", { replace: true });
      }
    } catch (err: any) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        showMessage("No Server Response");
      } else if (err.response?.status === 400) {
        if(resetToken==""){
          setErrMsg("Empty Verification Code or email.");
        }else{
          setErrMsg("Verification Code is Invalid or Expired.");
        }
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        showMessage("Unauthorized");
      } else if(err.response?.status === 404) {
        if(resetToken==""){
            setErrMsg("Empty reset password token or password.");
        }else{
            showMessage(err.response?.data.errors);
        }
      }else {
        setErrMsg("Code Verification Failed");
        showMessage("Code Verification Failed");
      }
      errRef.current !== null ? errRef.current.focus() : "";
    }
  };

  function showMessage(errMsg: string, status: "info" | "warning" | "success" | "error" | "loading" | undefined = "error") {
    toast({
      title: errMsg,
      position: "top-right",
      isClosable: true,
      status: status,
    });
  }

  return (
    <>
    <Layout></Layout>
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={companyLogo}
          alt="ensight logo"
          boxSize="30%"
          height={'100%'}
          objectFit="contain"
        />
        <Heading
          color="#5AC4E5"
          fontFamily="monospace"
          fontWeight="bold"
          mb="4"
        ></Heading>
        <p
          ref={errRef}
          style={{ color: errMsg ? "red" : "inherit" }}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={handleSubmit}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl variant="floating">
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="text"
                    disabled
                    placeholder=" "
                    onChange={(e) => setResetToken(e.target.value)}
                    value={resetToken}
                  />
                  <FormLabel mb='8px'>Token</FormLabel>
                </InputGroup>
              </FormControl>
              <FormControl variant="floating">
              <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder=""
                    required
                    onChange={(e) => setPwd(e.target.value)}
                    value={password}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                  <FormLabel mb='8px'>NEW Password</FormLabel>
                </InputGroup>
              </FormControl>
              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                  _hover={{ background: "#14A0BF" }}
                background={'#5AC4E5'}
                colorScheme="teal"
                width="full"
              >
                Submit Change
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
    </>
  );
}

export default ResetPassword;
