import React from "react";
import { ChakraProvider, Image, extendTheme} from "@chakra-ui/react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import NotFound from "./pages/not-found/NotFound.page";
import TechnicianLoginPage from "./pages/login/Technician-login.page";
import { Provider } from "react-redux";
import store from "./redux/store";
import RequireAuth from "./utils/RequireAuth";
import CreateDevicePage from "./pages/create-device/Create-device.page";
import OnboardingOverview from "./pages/onbording-overview/Onboarding-overview.page";
import CreateOrFindMetaUser from "./pages/create-or-find/Create-of-find-user.page";
import ResetPassword from "./pages/reset-password/Reset-password.page";
import Layout from "./pages/header/layout.header";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container as Element);
const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)"
};
const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles
              }
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
              ...activeLabelStyles
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "#EDF2F7",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top"
            }
          }
        }
      }
    }
  }
});

const App = () => (
  <Routes>
      <Route path="/" element={<TechnicianLoginPage />} />
      <Route path="login" element={<TechnicianLoginPage />} />
      <Route path="reset-user-password" element={<ResetPassword />} />
      <Route element={<RequireAuth />}>
        <Route path="/find-create-user" element={<CreateOrFindMetaUser/>} />
        <Route path="/create-device" element={<CreateDevicePage/>} />
        <Route path="/onboarding-success" element={<OnboardingOverview/>} />
      </Route>
      <Route path="*" element={<NotFound />} />
  </Routes>
);
 
root.render(
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </Provider>
    </ChakraProvider>
  );
  //memo: skip rendering the component if its props have not changed
  export default React.memo(App);