import {
  Heading,
  Text,
  Flex,
  useColorModeValue,
  Button
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Layout from "../header/layout.header";
const OnboardingOverview = () => {
  const { auth, setAuth }: any = useAuth();
  const { user_customer_name } = useSelector((state: any) => state.user);
  let navigate = useNavigate();
  function handleSignOut() {
    sessionStorage.setItem("authToken", "");
    sessionStorage.setItem("uid", "");
    sessionStorage.setItem("userId", "");
    sessionStorage.setItem("userName", "");
    setAuth({
      userId: "",
      accessToken: "",
      userName: "",
      user_details: "",
    });
  }
  return (
    <>
      <Layout></Layout>

      <Flex w="100vw"
        h="100vh"
        align="center"
        justify="center"
        direction="column"
      >
        <Heading color={useColorModeValue('teal.200', 'teal.700')} variant="brand" as='h1' size='4xl' noOfLines={1} >User Onboard!</Heading>
        <Heading as="h1" size="xl">
          User {user_customer_name} succesfuly added to our systems by {auth?.userName}
        </Heading>
        <Text mt={4}>
          do you want to{" "}
          <Button color={useColorModeValue('teal.200', 'teal.700')} _hover={{ background: "#14A0BF" }} background={'#5AC4E5'} variant='link' onClick={handleSignOut}>Log Out</Button>
          {" "}now, or{" "}
          <Button color={useColorModeValue('teal.200', 'teal.700')} _hover={{ background: "#14A0BF" }} background={'#5AC4E5'} variant='link' onClick={() => { navigate("/find-create-user", { replace: true }); }}>onboard another</Button>
          {" "}User?
        </Text>
      </Flex>
    </>
  );
};

export default OnboardingOverview;