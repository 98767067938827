import { useRef, useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  FormControl,
  useToast,
  Image,
  FormLabel,
  Select,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import companyLogo from "../../assets/icons/icon.svg";
import { ChevronDownIcon } from "@chakra-ui/icons";
import useAuth from "../../hooks/useAuth";

function Layout() {
  const [technicianName, setTechnicianname] = useState(sessionStorage.getItem("userName"));
  const { auth, setAuth }: any = useAuth();

  function handleSignOut() {
    sessionStorage.setItem("authToken", "");
    sessionStorage.setItem("uid", "");
    sessionStorage.setItem("userId", "");
    sessionStorage.setItem("userName", "");
    setAuth({
      userId: "",
      accessToken: "",
      userName: "",
      user_details: "",
    });
  }


  return (
    <div className="header">
      <div>
        <Image src={companyLogo} alt="Icon" width="50" height="50" />
      </div>
      <div className="username">
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            {technicianName}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={handleSignOut}>Log Out</MenuItem>
          </MenuList>
        </Menu>
      </div>
    </div>
  );
}
export default Layout;
